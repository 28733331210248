<template>
    <div class="bg" @touchmove.prevent @mousewheel.prevent>
        <div class="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
}
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(255, 255, 255, .5);
  .loading {
    width: 150px;
    height: 15px;
    margin: 0 auto;
    position: relative;
    margin-top: 100px;
    span {
      position: absolute;
      width: 15px;
      height: 100%;
      border-radius: 50%;
      background: #1740df;
      -webkit-animation: load 1.04s ease-in infinite alternate;
    }

    span:nth-child(1) {
      -webkit-animation-delay: 0.13s;
    }
    span:nth-child(2) {
      -webkit-animation-delay: 0.26s;
    }
    span:nth-child(3) {
      -webkit-animation-delay: 0.39s;
    }
    span:nth-child(4) {
      -webkit-animation-delay: 0.52s;
    }
    span:nth-child(5) {
      -webkit-animation-delay: 0.65s;
    }
  }
}

@-webkit-keyframes load {
  0% {
    opacity: 1;
    -webkit-transform: translate(0px);
  }
  100% {
    opacity: 0.2;
    -webkit-transform: translate(150px);
  }
}
</style>