import createPersistedState from 'vuex-persistedstate'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    router: [],
    activeIndex:{
      fatherIndex:0,
      childrenIndex:0
    },
    is_loading:false
  },
  mutations: {
    SET_ROUTER: (state, router) => {
      state.router = router.filter(e=>!e.meta.hidden)
      
      router.map(item => {
        if(item.children) item.children = item.children.filter(e => !e.meta.hidden)
      })
    },
    SET_INDEX: (state, index) => {
      state.activeIndex = index
    },
    SET_LOADING: (state, loading) => {
      state.is_loading = loading
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    // reducer(data) {
    //     return {
    //         // 设置只储存state中的address
    //         router: data.router
    //     }
    // }
})]

})
