import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import Layout from "../Layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    meta: {
      hidden: true,
    },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/home/index"),
        meta: {
          title: "首页",
        },
      },
    ],
  },
  {
    path: "/product",
    component: Layout,
    redirect: "/product/companyCloud",
    meta: {
      title: "产品",
    },
    children: [
      {
        path: "companyCloud",
        name: "CompanyCloud",
        component: () => import("@/views/product/company-cloud"),
        meta: {
          title: "羽林二类电商云",
          img: "img/index/erlei.png",
        },
      },
      {
        path: "promoteCloud",
        name: "PromoteCloud",
        component: () => import("@/views/product/promote-cloud"),
        meta: {
          title: "羽林品牌企业推广云",
          img: "img/index/qiye.png",
        },
      },
    ],
  },
  {
    path: "/solution",
    component: Layout,
    redirect: "/solution/dataCenter",
    meta: {
      title: "解决方案",
    },
    children: [
      {
        path: "dataCenter",
        name: "DataCenter",
        component: () => import("@/views/solution/data-center"),
        meta: {
          title: "羽林数据中台",
          img: "img/index/meidle.png",
        },
      },
    ],
  },
  {
    path: "/userCenter",
    component: Layout,
    redirect: "/userCenter/manual",
    meta: {
      title: "用户中心",
    },
    children: [
      {
        path: "manual",
        name: "Manual",
        component: () => import("@/views/UserCenter/manual"),
        meta: {
          title: "使用手册",
          img: "img/index/shouce.png",
        },
      },
      // {
      //   path: "news",
      //   name: "News",
      //   component: () => import("@/views/UserCenter/news"),
      //   meta: {
      //     title: "羽林新闻",
      //     img: "img/index/xinwen.png",
      //   },
      // },
      // {
      //   path: "newDetail",
      //   name: "NewDetail",
      //   component: () => import("@/views/UserCenter/new-detail"),
      //   meta: {
      //     title: "新闻详情",
      //     hidden: true,
      //   },
      // },
    ],
  },
  {
    path: "/aboutUs",
    component: Layout,
    redirect: "/aboutUs/company",
    meta: {
      title: "关于我们",
    },
    children: [
      {
        path: "company",
        name: "Company",
        component: () => import("@/views/AboutUs/company"),
        meta: {
          title: "公司介绍",
          img: "img/index/gongsi.png",
        },
      },
      {
        path: "partner",
        name: "Partner",
        component: () => import("@/views/AboutUs/partner"),
        meta: {
          title: "合作伙伴",
          img: "img/index/huoban.png",
        },
      },
      {
        path: "connect",
        name: "Connect",
        component: () => import("@/views/AboutUs/connect"),
        meta: {
          title: "联系我们",
          img: "img/index/lianxi.png",
        },
      },
    ],
  },
  {
    path: "/",
    component: Layout,
    redirect: "/demo",
    meta: {
      hidden: true,
    },
    children: [
      {
        path: "demo",
        name: "Dome",
        component: () => import("@/views/demo/index"),
        meta: {
          title: "demo",
          hidden: true,
        },
      },
    ],
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import("@/views/chat/index"),
    meta: {
      title: "在线咨询",
      hidden: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login"),
    meta: {
      title: "登录",
      hidden: true,
    },
  },
];

// 拷贝路由，用于渲染导航
let router_list = copy(routes);

function copy(obj) {
  var newobj = obj.constructor === Array ? [] : {};
  if (typeof obj !== "object") {
    return;
  }
  for (var i in obj) {
    newobj[i] = typeof obj[i] === "object" ? copy(obj[i]) : obj[i];
  }
  return newobj;
}

// 将用于渲染的路由保存至vuex
store.commit("SET_ROUTER", router_list);

const router = new VueRouter({
  routes,
});

// 全局前置路由守护，用于开启loading状态
router.beforeEach((to, from, next) => {
  store.commit("SET_LOADING", true);
  next();
});

// 全局后置路由守护，用于处理路由激活状态，以及关闭loading状态
router.afterEach((to, from, next) => {
  let obj = {
    fatherIndex: 0,
    childrenIndex: 0,
  };

  if (to.name === "Chat") {
    return;
  } else {
    if (to.name === "NewDetail") {
      obj = {
        fatherIndex: 3,
        childrenIndex: 2,
      };
    } else {
      routes.forEach((item, index) => {
        if (item.children) {
          item.children.forEach((child, childIndex) => {
            if (to.name === child.name) {
              obj = {
                fatherIndex: index,
                childrenIndex: childIndex,
              };
              store.commit("SET_INDEX", obj);
            }
          });
        }
      });
    }
  }

  if (to.meta.title) {
    document.title = "羽林数据-" + to.meta.title;
  }

  // 路由跳转后，置顶
  window.scrollTo(0, 0);

  // 关闭loading
  store.commit("SET_LOADING", false);
});

export default router;
