<template>
  <div class="layout">
    <Header></Header>
    <div class="content" ref="content">
      <router-view/>
      <loading v-if="is_loading"></loading>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import loading from '../components/loading.vue'
import { mapState } from 'vuex'

export default {
  components: { Header, Footer, loading },
  data() {
    return {}
  },
  computed: {
    ...mapState(['is_loading'])
  },
  watch: {
    
  }
}
</script>

<style lang="less" scoped>
.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    position: relative;
    flex: 1;
    margin-top: 80px;
  }
}

@media all and (max-width: 1080px) {
  .content {
    margin-top: 40px !important;
  }
}
</style>
