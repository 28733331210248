<template>
  <div class="footer">
    <div class="container flex foooter_wrapper">
      <div class="footer_left footer_left_max">
        <img src="../assets/img/index/footer-logo.png" alt="">
      </div>
      <div class="footer_right">
        <div class="flex">
          <div class="footer_right_nav_list" v-for="item in router" :key="item.path">
            <div class="footer_right_nav_head">{{item.meta.title}} </div>
            <div class="mid_line"></div>
            <div class="footer_right_nav_list_n" v-for="child in item.children" :key="child.path">
              <router-link :to="item.path + '/' + child.path">{{child.meta.title}}</router-link>
            </div>
          </div>
          <div class="footer_qr">
            <div class="footer_qr_mini">
              <p>联系电话</p>
              <p>13880563034</p>
            </div>
            <p class="footer_tel">电话：13880563034</p>
            <div>
              <img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/4253cf16387702299482452009/f0.png" alt=""> 在线咨询
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="footer_beian">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">@2022 羽林数据 蜀ICP备2022028358号</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['router'])
  }
}
</script>

<style lang="less" scoped>
.footer {
  background: #1f2d3d;
  padding-top: 46px;

  .flex {
    display: flex;
  }

  .container {
    width: 1200px;
    margin: 0 auto;
  }

  .footer_left {
    margin-right: 136px;
    & > img {
      height: 62px;
      margin-bottom: 40px;
    }
  }

  .footer_right {
    flex: 1;
    .footer_tel {
      font-size: 18px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #7f8e9f;
      margin-bottom: 30px;
      text-align: center;
    }

    .footer_qr {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #7f8e9f;
        & > img {
          width: 120px;
          margin-bottom: 16px;
        }
      }
    }

    .footer_right_nav_list {
      margin-right: 50px;

      .footer_right_nav_head {
        font-size: 20px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #fff;
        margin-bottom: 18px;
      }
      .mid_line {
        height: 2px;
        background: rgba(255, 255, 255, 0.5);
        margin-right: -50px;
        margin-bottom: 40px;
      }
      .footer_right_nav_list_n {
        font-size: 14px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #7f8e9f;
        margin-bottom: 30px;
        cursor: pointer;
      }
    }

    .footer_right_nav_list:nth-last-child(2) {
      & > .mid_line {
        margin-right: 0;
      }
    }
  }

  .footer_beian {
    font-size: 16px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #7f8e9f;
    text-align: center;
    padding-top: 58px;
    padding-bottom: 4px;
  }

  .footer_qr_mini {
    display: none !important;
  }
}

@media all and (max-width: 1080px) {
  .footer {
    .container {
      width: 100%;
      flex-direction: column;
    }
    .footer_left {
      margin-right: 0;
      .footer_left_min {
        display: flex;
        padding: 0 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .footer_left_max {
      display: flex;
      justify-content: center;
    }

    .footer_right {
      padding: 0 20px;
      margin-top: 99px;
      margin-bottom: 20px;

      .footer_tel {
        display: none !important;
      }
      & div {
        display: flex !important;
        flex-wrap: wrap;
        width: 100%;
        &.footer_right_nav_list:first-child {
          width: 35%;
        }
        & > .footer_right_nav_list:nth-child(2) {
          width: 25%;
        }
      }

      .footer_right_nav_list {
        width: 20%;
        height: 100px;
        font-size: 12px;
        margin-right: 0 !important;
        margin-bottom: 50px;
        box-sizing: border-box;
        .footer_right_nav_head,
        .footer_right_nav_list_n {
          font-size: 12px;
        }

        .mid_line {
          margin-right: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }

      .footer_qr {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > div {
          color: #fff;
        }
        .footer_qr_mini {
          display: block !important;
          line-height: 20px;
          text-align: center;
          margin: 30px 0 20px;
        }
      }
    }

    .footer_beian {
      padding: 15px 0;
    }
  }
}
</style>