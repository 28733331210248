import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import 'element-ui/lib/theme-chalk/index.css';

import 'lib-flexible'

require('./assets/css/reset.css')


// flexible.js配置
// function refreshRem(){
//   var width = docEl.getBoundingClientRect().width;
//   var rem = undefined

//   if (width / dpr > 540) {
//       width = width * dpr;
//   }

//   console.log(width,dpr)

//   let flag = win.navigator.userAgent.match(
//       /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
//   );

//   if(flag === null) {
//       console.log("pc端");
//       rem = width / 10;
//   }else {
//       console.log("移动端");
//       rem = width / 2.2;   
//   }
//   docEl.style.fontSize = rem + 'px';
//   flexible.rem = win.rem = rem;
// }

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
