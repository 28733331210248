<template>
  <div class="header_wrapper">
    <div class="container">
      <!-- pc端导航 -->
      <div class="header">

        <h1></h1>

        <ul>
          <li :class="activeIndex.fatherIndex === 0 ? 'list_hover head_line_height active' : 'list_hover head_line_height'">
            <p class="p">
              <router-link to="/">首页</router-link>
            </p>
          </li>
          <!-- activeIndex.fatherIndex：判断一级路由是否添加激活样式 -->
          <li v-for="(item,index) in router" :key="index" :class="activeIndex.fatherIndex === index+1 ? 'list_hover active' : 'list_hover'">
            <!-- 一级路由标题 -->
            <button type="link">{{item.meta.title}}</button>
            <!-- 
              产品&&解决方案的二级路由，
              由于标题长度不同，导致显示二级路由下拉框时不居中，
              product_head_table_translate&&product_head_table_translate2用于单独设置偏移值
             -->
            <div :class="index === 0 ? 'product_table product_head_table_translate' : index === 1 ? 'product_table product_head_table_translate2' : 'product_table'">
              <!-- activeIndex.childrenIndex：判断二级路由是否添加激活样式 -->
              <p v-for="(itemTwo,childIndex) in item.children" :key="itemTwo.path" :class="activeIndex.childrenIndex === childIndex ? 'p' : ''">
                <router-link :to="item.path + '/' + itemTwo.path">
                  <img :src="require('../assets/'+itemTwo.meta.img)" alt="" /> {{itemTwo.meta.title}}
                </router-link>
              </p>
            </div>
          </li>
        </ul>

        <!-- <div class="demo_btn" :style="{display: isDemoBtn ? 'block' : 'none'}" @click="demoBtn">体验demo</div> -->

      </div>

      <!-- 移动端导航 -->
      <div class="min_header">
        <!-- logo -->
        <img src="../assets/img/index/logo.png" alt="">
        <div class="nav_btn" @click="close_nav">导航</div>
        <!-- 动画 -->
        <!-- 导航列表展示时，禁止滚动 -->
        <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeIn" leave-active-class="animate__fadeOut">
          <div class="bg_mask" v-show="is_nav_show" @touchmove.prevent @mousewheel.prevent>
            <transition name="animate__animated animate__bounce" enter-active-class="animate__fadeInDownBig" leave-active-class="animate__fadeOutUpBig">
              <ul v-show="is_list_show">
                <!-- 单独设置导首页的激活样式 -->
                <li :class="activeIndex.fatherIndex === 0 ? 'active' : ''">
                  <p class="p" style='background:#fff' @click='close_nav'>
                    <router-link to="/">首页</router-link>
                  </p>
                </li>
                <li v-for="(item,index) in router" :key="index" :class="activeIndex.fatherIndex === 0 ? '' : activeIndex.fatherIndex === index+1 ? 'active' : ''">
                  <button type="link" @click="open_nav(index+1)" :class="is_list_title_show === index+1 && flag ? 'button_active' : ''">{{item.meta.title}}</button>
                  <p :class="activeIndex.childrenIndex === childIndex && activeIndex.fatherIndex === index+1 ? 'p' : ''" v-for="(itemTwo,childIndex) in item.children" :key="itemTwo.path" @click="close_nav(index+1,childIndex)" v-show="is_list_title_show === index+1 && flag">
                    <router-link :to="item.path + '/' + itemTwo.path">
                      {{itemTwo.meta.title}}
                    </router-link>
                  </p>
                </li>
              </ul>
            </transition>
          </div>
        </transition>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import "animate.css";

export default {
  data() {
    return {
      // 移动端控制导航显示隐藏
      is_nav_show: false,
      // 移动端一级路由索引
      is_list_title_show: 0,
      // 移动端控制是否显示二级路由
      flag: false,
      // 控制多次点击
      num: 0,
      is_list_show: false,
      // demo按钮
      isDemoBtn: true,
    };
  },
  computed: {
    // 路由信息
    ...mapState(["router"]),

    // pc端控制导航激活样式
    ...mapState(["activeIndex"]),
  },
  mounted() {
    if (window.location.hash === "#/demo") {
      this.isDemoBtn = false;
    }

    // 监听从demo页返回时，显示demo按钮
    window.addEventListener("hashchange", (e) => {
      if (e.oldURL.indexOf("/demo") !== -1) {
        this.isDemoBtn = true;
      }
    });
  },
  methods: {
    // 打开二级路由
    open_nav(index) {
      if (this.is_list_title_show === index) {
        this.num += 1;

        if (this.num && this.num % 2 === 0) {
          this.flag = false;
          this.num = 0;
          return;
        }
      }
      this.is_list_title_show = index;
      this.flag = true;
    },

    // 关闭导航
    close_nav(a, b) {
      this.is_nav_show = !this.is_nav_show;
      this.is_list_show = !this.is_list_show;
    },

    // 体验demo
    demoBtn() {
      // 隐藏demo按钮
      this.isDemoBtn = false;
      this.$router.push("/demo");
    },
  },
};
</script>

<style lang="less" scoped>
.header_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 25, 89, 0.2);
  display: flex;
  align-items: center;
  z-index: 9999;

  .container {
    width: 1200px;
    margin: 0 auto;
    .header {
      display: flex;
      align-items: center;
      & > h1 {
        width: 215px;
        height: 55px;
        background-image: url("../assets/img/index/logo.png");
        background-size: 100% 100%;
        color: transparent;
      }
      & > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        margin-left: 105px;
        margin-right: 24px;
        flex: 1;
      }

      .head_line_height {
        line-height: 80px;
      }

      .header_user_img {
        width: 32px;
        margin-right: 16px;
      }

      .demo_btn {
        position: absolute;
        right: 150px;
        width: 120px;
        height: 34px;
        background: linear-gradient(150deg, #317bff, #1740df);
        box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.2);
        border-radius: 4px;
        text-align: center;
        line-height: 34px;
        font-size: 18px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #ffffff;
        margin-right: 12px;
        cursor: pointer;
      }
      .tel_number {
        font-size: 18px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
      }

      .list_hover {
        button:hover {
          color: #317bff !important;
        }
        button:hover:after {
          border-bottom-color: #317bff;
          border-left-color: #317bff;
        }
        .product_head_table_translate {
          transform: translateX(-140px) !important;
        }

        .product_head_table_translate2 {
          transform: translateX(-135px) !important;
        }

        .product_table {
          position: absolute;
          background: #ffffff;
          box-shadow: 0px 0px 4px 0px rgba(0, 25, 89, 0.2);
          border-radius: 4px;
          transform: translateX(-120px);
          top: 80px;
          padding: 30px;
          padding-bottom: 0px;
          display: none;
          z-index: 99;

          a {
            white-space: nowrap;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #333333;
            margin-bottom: 30px;
            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
            &:hover {
              text-decoration: none;
              color: #317bff;
            }
          }
        }
        & > p > a {
          font-size: 16px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
          position: relative;
          &:hover {
            color: #317bff;
          }
        }
        &:hover .product_table {
          display: inline-block;
        }
      }
    }

    li {
      font-size: 16px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      position: relative;
      height: 80px;
      &.active {
        .p {
          a {
            color: #317bff !important;
          }
        }

        & > button {
          color: #317bff !important;

          &::after {
            border-bottom-color: #317bff !important;
            border-left-color: #317bff !important;
          }
        }
      }
    }

    button {
      background: #fff;
      border: none;
      outline-style: none;
      cursor: pointer;
      font-size: 16px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #333333;
      height: 80px;
      line-height: 80px;
      &::after {
        border-bottom: 1px solid #1f2d3d;
        border-left: 1px solid #1f2d3d;
        content: "";
        display: inline-block;
        height: 6px;
        left: 7px;
        position: relative;
        top: -3px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 6px;
      }
    }

    .min_header {
      display: none;
    }
  }
}

@media all and (max-width: 1080px) {
  .header_wrapper {
    height: 40px;
    .container {
      .header {
        display: none;
      }

      .min_header {
        display: flex;
        z-index: 9999;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 40px;
        & > img {
          height: 30px;
        }
        .nav_btn {
          border: 1px solid #000;
          border-radius: 4px;
          padding: 4px;
          font-size: 14px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
        .bg_mask {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 40px;
          left: 0;
          background: rgba(255, 255, 255, 0.8);
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;
            background: #fff;
            p {
              width: 100%;
              text-align: center;
              height: 45px;
              line-height: 45px;
              background: #f8fcff;
              a {
                color: #000;
              }
            }
          }

          button {
            &::after {
              -webkit-transform: rotate(-135deg);
              transform: rotate(-135deg);
            }
          }

          .button_active {
            &::after {
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
}

// .list_select_hover > button:after {
//   border-bottom-color: #317bff !important;
//   border-left-color: #317bff !important;
// }
</style>